<template>
  <FormKit type="form" @submit="submitForm" class="space-y-4" :errors="errors">
    <FormKit
      name="username"
      v-model="form.email"
      :errors="data.psdata?.email"
      :label="$t('Email')"
      type="email"
      :rules="['required']"
    />
    <FormKit
      name="password"
      v-model="form.password"
      :errors="data.psdata?.password"
      :label="$t('Password')"
      type="password"
      :rules="['required', { min: 6 }]"
    />
  </FormKit>
</template>


<script setup lang="ts">
import { FormKit } from '@formkit/vue';
import useNotifications from '~/stores/notifications';
import { useSdk } from '~/sdk';

const emit = defineEmits(['success']);
const config = inject(Symbol.for('FormKitConfig'))
const { locale } = useI18n();
const { send } = useNotifications();
const auth = useAuth();
const cart = useCart();

config.locale = locale.value;

const form = ref({
  email: '',
  password: ''
});

const data = ref<any>({});
const errors = ref<any>([]);
const isLoading = ref(false);
const { t } = useI18n();

const submitForm = async () => {
  isLoading.value = true;
  errors.value = [];

  const res = await useSdk().commerce.login({
    email: form.value.email,
    password: form.value.password
  });

  data.value = res;

  if (res.code != 200) {
    errors.value = [...errors.value, ...(Array.isArray(data.value?.psdata?.list) ? data.value?.psdata?.list : [data.value?.psdata])];
    isLoading.value = false;
    return;
  }

  send({
    message: t('Login was successful!'),
    type: 'success',
  });

  await auth.refresh();
  await cart.refresh();

  isLoading.value = false;

  emit('success');
};
</script>
